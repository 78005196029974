




































import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { mapActions, mapMutations } from 'vuex'
import { CLASSIFIED_SEARCH_NS } from '~/store/modules/shared/classifieds/search/state'
import { SET_PARAM } from '~/store/modules/shared/classifieds/search/mutation-types'
import {
  fallbackIcon,
  sortOptionsIcons
} from '~/constants/classified/search/sort'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  data: () => ({
    icons: {
      check: faCheck
    },
    loading: false,
    modalVisible: false,
    clicked: -1
  }),
  computed: {
    sortFields() {
      return this.$store.state.classifieds.search.sortFields || []
    }
  },
  watch: {
    modalVisible(newModalVisibleValue) {
      this.$emit('visibility-changed', newModalVisibleValue)
    }
  },
  mounted() {
    this.clicked = -1
  },
  methods: {
    ...mapActions({
      search: 'classifieds/search/search'
    }),
    ...mapMutations(CLASSIFIED_SEARCH_NS, {
      setParam: SET_PARAM
    }),
    getIcon(id) {
      return (sortOptionsIcons[id] || fallbackIcon).icon
    },
    showModal() {
      this.modalVisible = true
    },
    hideModal() {
      this.modalVisible = false
    },
    fieldClicked(i) {
      const sortField = this.sortFields[i]
      this.clicked = i
      this.setParam({ name: 'sort', value: sortField.value })
      this.setParam({ name: 'pg', value: 1 })
      this.search()
      window.scroll(0, 0)
      this.hideModal()
    }
  }
})
