






































import { ciFilterCategoriesV2 } from '~/icons/source/regular/filter-categories-v2'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    iconOnly: { type: Boolean, default: false },
    rightSideCounter: { type: Boolean, default: false },
    facetsCount: { type: Number, required: true },
    withoutLabel: { type: Boolean, default: false }
  },
  computed: {
    icons() {
      return {
        filterIcon: ciFilterCategoriesV2
      }
    }
  }
})
